body {
    font-family: Arial;
    color: red;
}


.mess {
    color: red;
    font-weight: bold;
    margin-left: 550px;
    padding: auto;
}



.lis {
    color: #fff;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    text-decoration: none;
}

.uu {

    list-style-type: none;
    margin-left: 0px;
    margin-top: 20px;
    font-size: large;
    list-style-type: none;
}

.nn {
    list-style-type: none;
    margin-left: 0px;
    margin-top: 20px;
    font-size: large;
    list-style-type: none;
}




.clii {
    width: 7%;
    height: 35px;
    border: none;
    border: 1px solid;
    background-color: rgba(255, 251, 251, 0.904);
}






.aa {
    margin-bottom: 180px;
    margin-left: 100px;
    color: rgb(17, 0, 255);
}


.inputtt {
    display: block;
    height: 50px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.07);
    border-radius: 3px;
    padding: 0 10px;
    margin-top: 8px;
    font-size: 14px;
    font-weight: 300;



}

.sum {
    width: 10%;
    margin-top: 10px;
    margin-left: 50%;
    border-radius: 15px;
}

.sum:hover {
    color: white;
    background-color: transparent;
}

.inputtt.send {
    cursor: pointer;
    float: right;
    padding: 4px 5px 4px 10px;
    width: 50%;
    background-color: rgba(194, 13, 13, 0.07);
    border-radius: 3px;
    padding: 0 10px;
    margin-top: 8px;
    font-size: 14px;
    font-weight: 300;
}


::placeholder {
    color: #ffffff;
}