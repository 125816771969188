.formm {
    top: 50%;
    left: 50%;
    height: 500px;
    width: 600px;
    transform: translate(-50%, -50%);
    border-radius: 50px;
    box-shadow: 0 0 20px rgba(99, 92, 92, 0.6);
    padding: 50px 35px;
    background-color: var(--color1);
    position: absolute;
    font-family: serif;
    letter-spacing: 0.5px;
    outline: none;
    border: none;
    background-color: #3974f5;
    justify-content: center;
    align-items: center;

    /* Set height to 100vh for full-screen centering */
}


.hh {
    font-size: 40px;
    font-family: Serif;
    font-weight: 500;
    line-height: 42px;
    margin-right: 5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f6f6f8;
    padding: 15px;
}

label {
    display: block;
    margin-top: 30px;
    font-weight: 500;
    font-size: 16px;
    color: rgb(250, 244, 244);
    display: block;
    margin-bottom: 5px;




    font-size: 18px;

}


.aa {
    margin-bottom: 180px;
    margin-left: 100px;
    color: rgb(17, 0, 255);
}


input {
    height: 50px;
    width: 100%;
    border-radius: 3px;
    padding: 0 10px;
    margin-top: 8px;
    font-size: 14px;
    font-weight: 300;
    border: none;
    border-bottom: 1px solid #030775;

    border-radius: 50px;


}



.lo {
    background-color: #ffffff;
    padding: 0.9VW;
    font-size: 1.2VW;
    font-weight: 600;
    border-radius: 2vw;
    margin-bottom: 1em;
    border: #1a00e2;
    width: auto;
    margin-left: calc(85%/2);

    /* Set width to 0% when sidebar is closed */
    @media (max-width: 760px) {
        font-size: 2VW;
        margin-left: calc(90%/2);
    }
}



.lo:hover {
    background-color: #0890eb;
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
    color: white;
}



.invalid {
    color: red;
    font-size: 18px;
    padding: 20px;

    margin-top: 10px;
    margin-left: 15px;


}

.invald {
    color: rgb(255, 255, 255);
    font-size: 18px;
    padding: 20px;

    margin-top: 10px;
    margin-left: 5vw;


}


.bu {
    width: 100%;

    padding: 15px;
    font-size: 18px;
    border-radius: 2vw;
    cursor: pointer;
}



input:focus {
    outline: none;
}

/* here */
.cenn {
    background-color: rgba(255, 255, 255, 0.5);
    color: #00c8fa;

}

.blncen3 {

    margin-left: calc(89%/2);

    @media (max-width: 760px) {

        margin-left: calc(90%/2);

        @media (max-width: 480px) {

            margin-left: calc(80%/2);
        }
    }
}

.blncen {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.blncen2 {
    position: absolute;
    top: 58%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (max-width: 760px) {
        top: 57%;
    }
}

.blending-spinnerr {


    border: 4px solid rgba(27, 26, 26, 0.3);
    border-top-color: #00c8fa;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);

    }
}