.registration-form {
    padding: 100px 0;
}

.registration-formm {
    padding: 100px 0;
}

.registration-form form {
    background-color: #a4d5f1;
    max-width: 600px;
    margin: auto;
    padding: 10px 20px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.075);
}


.registration-formm .form {
    background-color: #a4d5f1;
    max-width: 600px;
    margin: auto;
    padding: 10px 20px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.075);
}

.registration-form .item {
    border-radius: 20px;
    margin-bottom: 25px;
    padding: 10px 20px;
}

.registration-formm .item {
    border-radius: 20px;
    margin-bottom: 25px;
    padding: 10px 20px;
}

.registration-form .create-account {
    font-weight: bold;
    background-color: #5791ff;
    border: none;
    color: white;

    padding: 0.9VW;
    font-size: 1.2VW;
    border-radius: 2vw;
    margin-bottom: 1em;
    width: auto;
    margin-left: calc(80%/2);

    @media (max-width: 760px) {
        font-size: 2VW;

        margin-left: calc(90%/2);

        @media (max-width: 480px) {

            font-size: 3VW;
            margin-left: calc(80%/2);
        }
    }



}

.registration-formm .create-account {
    border-radius: 30px;
    padding: 10px 20px;
    font-size: 18px;
    font-weight: bold;
    background-color: #5791ff;
    border: none;
    color: white;
    margin-left: 220px;
}

.registration-form .social-media {
    max-width: 600px;
    background-color: #002fff;
    margin: auto;
    padding: 5px 0;
    text-align: center;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    color: #9fadca;
    border-top: 1px solid #dee9ff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.075);
}

.registration-formm .social-mediaa {
    max-width: 600px;
    background-color: #5791ff;
    margin: auto;
    padding: 5px 0;
    text-align: center;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    color: #ffffff;
    border-bottom: 1px solid #dee9ff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.075);
}

.registration-formm .social-mediaa h3 {
    padding: 10px;
    color: #ffffff;
}

@media (max-width: 576px) {
    .registration-form form {
        padding: 50px 20px;
    }

    .registration-form .form-icon {
        width: 70px;
        height: 70px;
        font-size: 30px;
        line-height: 70px;
    }
}

@media (max-width: 576px) {
    .registration-formm form {
        padding: 50px 20px;
    }

    .registration-formm .form-icon {
        width: 70px;
        height: 70px;
        font-size: 30px;
        line-height: 70px;
    }
}

.invcode {
    color: red;
    padding: 20px;
    font-weight: bold;
    margin-top: 10px;
    margin-left: 10vw;


}

@media only screen and (max-width: 768px) {
    .invcode {
        margin-left: 20vw;
    }
}

@media only screen and (max-width: 480px) {
    .invcode {
        margin-left: 15vw;
        font-size: 12px;
    }
}

.resnd {

    text-align: center;

    color: #ffffff;
    cursor: pointer;
    list-style-type: none;
    text-decoration: none;

}

.resnd:hover {

    text-align: center;

    color: #0bceff;
    list-style-type: none;
}