.fieldse {
    background-color: white;




    margin: 1rem;
    border-radius: 20px;
    color: rgb(8, 169, 233);
    padding: 15px;
    position: relative;
    border-radius: 10px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.80);
}



.sp {
    font-weight: bold;
    margin-left: 30px;
    color: red;

}

.inpuu {
    width: 20%;
    height: 35px;
    border: none;
    border: 1px solid;
    background-color: rgba(255, 251, 251, 0.904);
    display: inline;
    margin-left: 50px;
}

.inpuu[type="button"] {
    background-color: #ccc;
    border: none;
    border: 1px solid #a1a0a0;
}

::placeholder {
    color: black;
}

.photo {
    float: right;
    border-radius: 8px;
    border: 1px solid #5a5252;
    border-radius: 4px;
    padding: 5px;
    width: 150px;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border: 1px solid #ddd;
}

.col {
    width: 145%;
    height: auto;
    right: 50;
    margin-left: 250px;

}

.coll {
    width: 145%;
    height: auto;
    right: 50;
    margin-left: 170px;
}

p {
    font-weight: bold;
    font-size: 20px;
    margin-left: 5px;
}





.image-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 60%;
    margin: auto;
    margin-bottom: 50px;
}

.media {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin: 10px;
    overflow: hidden;
    position: relative;
    color: aqua;

}

@media (max-width: 768px) {
    .image-wrapper {
        width: 10%;


    }
}

.media:hover {
    cursor: pointer;
}

.overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
}

.media:hover .overlay {
    opacity: 1;

}

img {
    width: 100%;
    z-index: -1;
    margin: auto;
    transform: scale(1);
    transition: all ease-in-out 0.5s;

}

.media:hover img {
    transform: scale(1.1);
    filter: blur(2px);

}

.image-details {
    text-align: center;
    color: white;
    font-size: 20px;
    z-index: 4;
    position: absolute;
    top: 100%;
    opacity: 0;
    transition: all ease-in-out 0.5s;

}

.media:hover .image-details {
    top: 40%;
    opacity: 1;
}

.lon {
    color: rgb(2, 2, 2);
    text-align: center;
}