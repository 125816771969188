body {
    font-family: Arial;

}





.lis {
    color: rgb(255, 253, 253);
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    text-decoration: none;

}

.uu {

    list-style-type: none;
    margin-left: 0px;
    margin-top: 20px;
    font-size: large;
    list-style-type: none;
}

.toast-message {
    background: rgb(243, 243, 243);
    color: #fff;
    font-size: 20px;
    width: 500px;
}

.nn {
    list-style-type: none;
    margin-left: 0px;
    font-size: large;
    list-style-type: none;
}

.fieldsety {
    background-color: #dad8d8;
    padding: 10px;
    border: 1px solid #ccc;
    margin: 40px;
}

.legeendd {
    border: 1px solid #ccc;
    padding: 20px;
    float: none;
    width: auto;
    font-size: large;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}


.clii {
    width: 7%;
    height: 35px;
    border: none;
    border: 1px solid;
    background-color: rgba(255, 251, 251, 0.904);
}





.aa {
    margin-bottom: 180px;
    margin-left: 100px;
    color: rgb(17, 0, 255);
}


.inputtt {
    display: block;
    height: 50px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.07);
    border-radius: 3px;
    padding: 0 10px;
    margin-top: 8px;
    font-size: 14px;
    font-weight: 300;



}

.sum {
    width: 10%;
    margin-top: 10px;
    margin-left: 50%;
    border-radius: 15px;
}

.sum:hover {
    color: white;
    background-color: transparent;
}

.inputtt.send {
    cursor: pointer;
    float: right;
    padding: 4px 5px 4px 10px;
    width: 50%;
    background-color: rgba(194, 13, 13, 0.07);
    border-radius: 3px;
    padding: 0 10px;
    margin-top: 8px;
    font-size: 14px;
    font-weight: 300;
}


::placeholder {
    color: #0f0d0d;
}









.bl {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: -1;
    width: 100%;
    height: 100%;
    -webkit-filter: blur(5px);
    filter: blur(5px);
}


.fi {

    margin: 1rem;
    border-radius: 20px;
    border: #5a5252;
    padding: 15px;
    position: relative;
    border-radius: 10px;
    width: max-content;

    border-radius: 20px;
    border-radius: 10px;
}

.fil {
    margin-left: 550px;

}

h5 {
    font-weight: bold;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

/* here */
.cenn {
    background-color: rgba(255, 255, 255, 0.5);
    color: #00c8fa;

}

.blncen3 {

    margin-left: calc(89%/2);

    @media (max-width: 760px) {

        margin-left: calc(90%/2);

        @media (max-width: 480px) {

            margin-left: calc(80%/2);
        }
    }
}

.blncen {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.blncen2 {
    position: absolute;
    top: 58%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (max-width: 760px) {
        top: 57%;
    }
}

.blending-spinnerr {


    border: 4px solid rgba(27, 26, 26, 0.3);
    border-top-color: #00c8fa;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);

    }
}