 .table {
     border-spacing: 0 15px;
     border-collapse: separate;
 }

 .table thead tr th,
 .table thead tr td {
     vertical-align: middle;
     border: #0f0d0d;
     color: rgb(8, 169, 233);
 }

 .table tbody tr th,
 .table tbody tr td {

     vertical-align: middle;
 }

 .table thead tr th:nth-last-child(1),
 .table thead tr td:nth-last-child(1),
 .table tbody tr th:nth-last-child(1),
 .table tbody tr td:nth-last-child(1) {
     text-align: center;
 }








 body {
     font-family: Arial;
 }

 .split {
     height: 100%;
     position: absolute;
     z-index: 1;
     overflow-x: hidden;
 }



 .right {
     right: 5px;
     width: 100%;
     background-color: rgb(211, 231, 236);

 }





 .lis {
     color: #fff;
     font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
     text-decoration: none;

 }

 .uu {

     list-style-type: none;
     margin-left: 0px;
     margin-top: 20px;
     font-size: large;
     list-style-type: none;
     padding-top: 5px;
 }



 .nn {
     list-style-type: none;
     margin-left: 0px;
     font-size: large;
     list-style-type: none;
 }

 .fieldsety {
     background-color: #dad8d8;
     padding: 10px;
     border: 1px solid #ccc;
     margin: 40px;
 }

 .legeendd {
     border: 1px solid #ccc;
     padding: 20px;
     float: none;
     width: auto;
     font-size: large;
     font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
 }


 .clii {
     width: 7%;
     height: 35px;
     border: none;
     border: 1px solid;
     background-color: rgba(255, 251, 251, 0.904);
 }






 .aa {
     margin-bottom: 180px;
     margin-left: 100px;
     color: rgb(17, 0, 255);
 }


 .inputtt {
     display: block;
     height: 50px;
     width: 100%;
     background-color: rgba(255, 255, 255, 0.07);
     border-radius: 3px;
     padding: 0 10px;
     margin-top: 8px;
     font-size: 14px;
     font-weight: 300;



 }

 .sum {
     width: 10%;
     margin-top: 10px;
     margin-left: 50%;
     border-radius: 15px;
 }

 .sum:hover {
     color: white;
     background-color: transparent;
 }

 .inputtt.send {
     cursor: pointer;
     float: right;
     padding: 4px 5px 4px 10px;
     width: 50%;
     background-color: rgba(194, 13, 13, 0.07);
     border-radius: 3px;
     padding: 0 10px;
     margin-top: 8px;
     font-size: 14px;
     font-weight: 300;
 }


 ::placeholder {
     color: #0f0d0d;
 }




 .main-content {
     padding-top: 100px;
     padding-bottom: 100px;
 }

 .active-circle {
     height: 10px;
     width: 10px;
     border-radius: 10px;
     margin-right: 5px;
     display: inline-block;
 }







 /*-------------------------------*/
 /*     Sidebar nav styles        */
 /*-------------------------------*/

 .sidebar-nav {
     position: absolute;
     top: 0;
     width: 220px;
     margin: 0;
     padding: 0;
     list-style: none;
 }

 .sidebar-nav li {
     position: relative;
     line-height: 20px;
     display: inline-block;
     width: 100%;
 }



 .sidebar-nav li:hover {
     background: rgb(15, 161, 219) !important;
     border-radius: 10px;
     margin-left: 10px;
 }



 .sidebar-nav li a {
     display: block;
     color: rgb(8, 169, 233);
     padding: 10px 15px 10px 30px;
     font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
     font-size: large;
 }

 .sidebar-nav li a:hover {
     color: #ffffff;
 }

 .sidebar-header {
     text-align: center;
     font-size: 20px;
     position: relative;
     width: 100%;

 }

 .sidebar-brand {
     height: 65px;
     position: relative;

     padding: 1em;
 }

 .sidebar-brand h2 {
     color: rgb(8, 169, 233);
 }